@mixin font ($size, $height: $size, $weight: 500, $style: normal) {
    font-size: $size;
    font-style: $style;
    font-weight: $weight;
    line-height: $height;
}

@mixin media-tablet {
    @media (max-width: $tablet-width) { @content; }
}

@mixin media-mobile {
    @media (max-width: $mobile-width) { @content; }
}