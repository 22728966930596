.contacts {
  padding-top: 8.4rem;
  padding-bottom: 9.2rem;
  border-bottom: solid 0.1rem $color-primary;
  display: flex;
  justify-content: space-between;

  position: relative;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 4.8rem;
    text-align: center;
  }
  @media (max-width: $tablet-width) {
   padding-top: 6rem;
  }
}

.contacts__info {
  display: flex;
  flex-direction: column;
  gap: 3.6rem;
  max-width: 44.5rem;
  flex-basis: 50%;

  @media (max-width: $tablet-width) {
    gap: 2rem;
  }
}
.contacts__form {
  max-width: 44.5rem;
  width: 100%;
}

.formlines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  gap: 3rem;
}

.contscts__block__background {
  position: absolute;
  bottom: 4rem;
  left: -27rem;
  z-index: 2;

  @media (max-width: $tablet-width) {
    bottom: 2rem;
    left: -30rem;
  }

  @media (max-width: $mobile-width) {
    bottom: 9.5rem;
    left: -26rem;
  }
}