.skills {
  border-top: solid 0.1rem $color-primary;
  padding-top: 7.2rem;
  padding-bottom: 16.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  row-gap: 5.8rem;
  column-gap: 3rem;

  position: relative;

  @media (max-width: 1400px) {
    column-gap: 0.2rem;
  }

  @media (max-width: $tablet-width) {
    column-gap: 0.7rem;
    row-gap: 5.2rem;
    padding-top: 5.2rem;
    padding-bottom: 10rem;
  }

  @media (max-width: calc($tablet-width - 1px)) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: $mobile-width) {
    padding-top: 4rem;
    gap: 2.4rem;
    padding-bottom: 4rem;
    border-bottom: 0.1rem solid $color-primary;
  }
}

.skill__block {
  width: 34.5rem;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;

  @media (max-width: calc($tablet-width - 1px)) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: $mobile-width) {
    gap: 0.1rem;
  }
}

.skill__name {
  color: $color-primary;
  @extend %font-primary;
  @include font($size: 4.8rem, $height: 5.6rem, $weight: 700);
  letter-spacing: -1.5px;

  @media (max-width: $mobile-width) {
    @include font($size: 3.2rem, $height: 4rem, $weight: 700);
    letter-spacing: -1px;
  }
}

.skills__block__background {
  position: absolute;
  bottom: 11.3rem;
  right: -16rem;
  z-index: 2;

  @media (max-width: $mobile-width) {
    right: -12rem;
    bottom: 6.3rem;
  }
}