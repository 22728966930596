header {
  position: relative;
  padding-top: 3.9rem;
  padding-bottom: 21.2rem;

  @media (max-width: $tablet-width) {
    padding-top: 2.9rem;
    padding-bottom: 6rem;
  }

  @media (max-width: $mobile-width) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 8rem;
  }
}

.header__block__background {
  position: absolute;
  top: 13.3rem;
  left: -17rem;
  z-index: 2;

  @media (max-width: $tablet-width) {
    top: 8.6rem;
    left: -20rem;
  }

  @media (max-width: $mobile-width) {
    top: 13rem;
  }
}

.photo__background {
  background-color: $color-background-light;
  width: 44.5rem;
  height: 72rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  overflow: hidden;

  img {
    z-index: 3;
    height: 100%;
  }

  @media (max-width: 1300px) {
    right: -15rem;
    width: 32.2rem;
    height: 60rem;
  }

  @media (max-width: $tablet-width) {
    //width: 32.2rem;
    //height: 60rem;
    right: -3.2rem
  }

  @media (max-width: $big-mobile-width) {
    width: 17.4rem;
    height: 38.3rem;
    top: 0;
    right: 8.5rem;
  }
}

.greeting__block {
  max-width: 71rem;
  padding-top: 12.7rem;

  @media (max-width: $tablet-width) {
    padding-top: 9rem;
    max-width: 44.5rem;
  }

  @media (max-width: $big-mobile-width) {
   padding-top: 33.3rem;
    text-align: center;
  }
}

h1 {
  position: relative;
  z-index: 5;

  & span {
    display: inline-block;
    border-bottom: $color-additional solid 0.6rem;
  }
}

.about_text {
  color: $color-secondary;
  @extend %font-primary;
  @include font($size: 1.8rem, $height: 2.8rem, $weight: 500);
  max-width: 44.5rem;
  margin: 0;
  padding-top: 4.3rem;
  padding-bottom: 7.3rem;

  position: relative;             //Check on desktop
  z-index: 5;

  @media (max-width: $tablet-width) {
    padding-top: 7.5rem;
    padding-bottom: 3.7rem;
  }

  @media (max-width: $mobile-width) {
    padding-top: 2.2rem;
    padding-bottom: 2.5rem;
    text-align: center;
    @include font($size: 1.6rem, $height: 2.6rem, $weight: 500);
  }
}