$color-primary: #FFFFFF;
$color-secondary: #D9D9D9;
$color-additional: #4EE1A0;
$color-background: #151515;
$color-background-light: #242424;
$color-error: #FF6F5B;

$desktop-width: 1440px;
$laptop-width: 1024px;
$content-width: 1110px;
$tablet-width: 768px;
$big-mobile-width: 425px;
$mobile-width: 375px;

$cursor: url('../../assets/svg/pointer.svg') 13 14, pointer;