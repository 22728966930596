.container,
.contacts__container {
  max-width: $desktop-width;
  width: 100%;
  margin: auto;
}

.container {
  background-color: $color-background;
}

.contacts__container {
  background-color: $color-background-light;
}