@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');

//Abstract
@import "sass/abstract/constants";
@import "sass/abstract/placeholders";
@import "sass/abstract/mixins";

//Base
@import "sass/base/base";
@import "sass/base/container";
@import "sass/base/wrapper";
@import "sass/base/link";
@import "sass/base/forms";

//Components
@import "sass/components/social__block";
@import "sass/components/header";
@import "sass/components/skills";
@import "sass/components/projects";
@import "sass/components/contacts";
@import "sass/components/footer";