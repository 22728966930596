.social__block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $mobile-width) {
    flex-direction: column;
    gap: 2rem;
  }
}

.logo {
  color: $color-primary;
  @extend %font-primary;
  @include font($size: 3.2rem, $height: 3.2rem, $weight: 700);
  letter-spacing: -0.444444px;
  margin: 0;

  z-index: 5;           //Check if its okay on desktop and tablet

  @media (max-width: $mobile-width) {
   font-size: 2.4rem;
  }
}

.social__block__links {
  display: flex;
  gap: 3.2rem;
  padding-right: 2.95rem;

  @media (max-width: $tablet-width) {
    padding-right: 0;
    align-items: center;
    gap: 3.1rem;
  }

  @media (max-width: $mobile-width) {
    gap: 2.56rem;
  }

}

.social_link {
  cursor: $cursor;
  transition: fill 0.2s ease-in-out;
  fill: $color-primary;
  position: relative;
  z-index: 5;
  &:hover {
    fill: $color-additional;
  }

  @media (max-width: $mobile-width) {
    height: 1.92rem;
    width: 1.92rem;
  }
}