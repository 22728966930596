.wrapper {
  padding: 0 calc(($desktop-width - $content-width) / 2);
  overflow-x: hidden;

  @media (max-width: $laptop-width) {
    padding: 0 7rem;
  }

  @media (max-width: $tablet-width) {
    padding: 0 3rem;
  }

  @media (max-width: $mobile-width) {
    padding: 0 1.6rem;
  }

}