* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  @extend %font-primary;
  @include font($size: 2rem, $height: 2.4rem);
}

h1, h2 {
  @extend %font-primary;
  @include font($size: 8.8rem, $height: 8.8rem, $weight: 700);
  color: $color-primary;
  letter-spacing: -2.5px;
  margin: 0;
  max-width: 72rem;

  @media (max-width: $tablet-width) {
    @include font($size: 7.2rem, $height: 7.2rem, $weight: 700);
    letter-spacing: -2.04545px;
  }

  @media (max-width: $big-mobile-width) {
    @include font($size: 4rem, $height: 4rem, $weight: 700);
    letter-spacing: -1.13636px;
  }
}

h3 {
  @extend %font-primary;
  @include font($size: 4.8rem, $height: 5.6rem, $weight: 700);
  color: $color-primary;
  letter-spacing: -1.5px;
  margin: 0;
}

p, span {
  margin: 0;
}

.secondary_text {
  color: $color-secondary;
  @extend %font-primary;
  @include font($size: 1.8rem, $height: 2.8rem, $weight: 500);

  @media (max-width: $mobile-width) {
    @include font($size: 1.6rem, $height: 2.6rem, $weight: 500);
  }
}

input {
  padding: 0;
  margin: 0;
}

.react_link {
  text-decoration: none;
}