.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 11.1rem;

  @media (max-width: $tablet-width) {
    padding-bottom: 10.5rem;
  }

  @media (max-width: $mobile-width) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}

.projects__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.projects__block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 6.9rem;

  padding-top: 8rem;

  @media (max-width: $tablet-width){
    padding-top: 6rem;
    row-gap: 6rem;
  }

  @media (max-width: $mobile-width) {
    padding-top: 4rem;
    row-gap: 3.9rem;
  }
}



.projects__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //justify-content: space-between;
  align-items: flex-start;

  max-width: calc(50% - 1.5rem);

  @media (max-width: 564px) {
    max-width: 100%
  }
}

.project__image {
  max-width: 54rem;
  max-height: 40rem;

  img {
    width: 100%;
    height: 100%
  }
}

.project__image {
  position: relative;

  .projects__links_desktop {
    display: none;
  }

  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    mix-blend-mode: normal;
    opacity: 0.75;
    .projects__links_desktop {
      display: flex;
    }
  }
}

.projects__links_desktop {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.projects__links_small {
  display: none;
  margin-top: auto;

  @media (max-width: $tablet-width){
    display: flex;
    gap: 3rem;
    padding-top: 2rem;
  }
}

.project__name {
  color: $color-primary;
  @extend %font-primary;
  @include font($size: 2.4rem, $height: 3.2rem, $weight: 700);
  padding: 1.5rem 0 0.7rem 0;
  text-transform: uppercase;
}

.project__stack__list {
  display: flex;
  justify-content: flex-start;
  row-gap: 0.2rem;
  column-gap: 2rem;
  flex-wrap: wrap;
}

.project__stack__item {
  color: $color-secondary;
  @extend %font-primary;
  @include font($size: 1.8rem, $height: 2.8rem, $weight: 500);
  text-transform: uppercase;
}

.project__description {
  color: $color-secondary;
  @extend %font-primary;
  @include font($size: 1.6rem, $height: 2.6rem, $weight: 500);
  // @include font($size: 1.8rem, $height: 2.8rem, $weight: 500);
  max-width: 44.5rem;
  margin: 0;
  padding-top: 1.8rem;
  text-align: justify;
  }